import React, { useState } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <div className="logoText">ContaDeal</div>
      </div>
      <span className="drawer-trigger" onClick={toggleDrawer}>
        &#9776; {/* Hamburger icon */}
      </span>
      <div className={`drawer ${drawerOpen ? 'active' : ''}`}>
        <span className="close-btn" onClick={toggleDrawer}>&times;</span>
        <div className="menu">
          <a href="#home">Home</a>
          <a href="#services">Services</a>
          <a href="#features">Features</a>
          <a href="#pricing">Pricing</a>
          <a href="#team">Team</a>
          <a href="#blog">Blog</a>
          <a href="#contact">Contact Us</a>
        </div>
      </div>
      <div className="menu"> {/* Ensure only one menu */}
        <a href="#home">Home</a>
        <a href="#services">Services</a>
        <a href="#features">Features</a>
        <a href="#pricing">Pricing</a>
        <a href="#team">Team</a>
        <a href="#blog">Blog</a>
        <a href="#contact">Contact Us</a>
      </div>
      <div className="auth-buttons">
        <button onClick={() => window.location.href = 'https://app.contadeal.ro/login'}>Login</button>
        <button onClick={() => window.location.href = 'https://app.contadeal.ro/signup'}>Inregistreaza-te</button>
      </div>
    </nav>
  );
};

export default Navbar;
