import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import Services from '../components/Services';
import Footer from '../components/Footer';
import './LandingPage.css';

import axios from 'axios';

const LandingPage = () => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    axios.get('/sections.json')
      .then(response => {
        let data = response.data;
        
        // Check if data is a string and parse it
        if (typeof data === 'string') {
          console.warn('Response data is a string. Parsing it...');
          data = JSON.parse(data);
        }
        
        console.log('Fetched sections:', data);
        
        if (Array.isArray(data)) {
          setSections(data);
        } else {
          console.error('Expected an array but got:', typeof data);
        }
      })
      .catch(error => console.error('Error fetching sections:', error));
  }, []);

  return (
    <div>
      <Helmet>
        <title>ContaDeal - Online Accounting Software</title>
        <meta name="description" content="ContaDeal is a smart and simple online accounting software for managing your business expenses and financial transactions." />
        <meta name="keywords" content="accounting software, business management, expense tracking, financial transactions" />
        <meta property="og:title" content="ContaDeal - Online Accounting Software" />
        <meta property="og:description" content="Manage and track your business expenses and financial transactions effortlessly with ContaDeal." />
        <meta property="og:image" content="URL to an image representing your app" />
        <meta property="og:url" content="https://yourwebsite.com" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Navbar />
      <Header />
      <Services sections={sections} />
      <Footer />
    </div>
  );
};

export default LandingPage;
