import React, { useState } from 'react';
import './EditableSection.css';

const EditableSection = ({ section, onSave }) => {
  const [title, setTitle] = useState(section.title);
  const [content, setContent] = useState(section.content);

  const handleSave = () => {
    onSave({ ...section, title, content });
  };

  return (
    <div className="editor">
      <input
        type="text"
        className="editor-input"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <textarea
        className="editor-textarea"
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
      <button className="editor-button" onClick={handleSave}>Save</button>
    </div>
  );
};

export default EditableSection;
