import React from 'react';
import './Header.css';
import screen1 from '../images/screen1.png';

const Header = () => {
  return (
    <header className="hero-header" id="home">
      <div className="hero-content">
        <h1>Manage Your Business with Ease</h1>
        <p>Simple, smart online accounting software</p>
        <div className="action-buttons">
          <button className="primary-button">Get Started</button>
          <button className="secondary-button">Try Free for 30 Days</button>
        </div>
      </div>
      <div className="hero-image">
        <img src={screen1} alt="Software Screenshot" />
      </div>
     
    </header>
  );
};

export default Header;
