import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import './Services.css';

const Services = ({ sections }) => {
  const colors = ['color-blue', 'color-green', 'color-orange', 'color-purple'];

  if (!Array.isArray(sections)) {
    console.error('Sections is not an array', sections);
    return null; // or return a fallback UI
  }

  return (
    <div className="services" id="services">
      <div className="service-groups">
        {sections.map((section, index) => (
          <ServiceItem
            key={index}
            section={section}
            index={index}
            colorClass={colors[index % colors.length]}
          />
        ))}
      </div>
    </div>
  );
};

const ServiceItem = ({ section, index, colorClass }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const springProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translate3d(0,0,0)' : 'translate3d(0,40px,0)',
    config: { tension: 1180, friction: 30 },
  });

  return (
    <animated.div ref={ref} style={springProps} className={`service-item-container ${colorClass}`}>
      <div className="service-item">
        <div className="content">
          <h3>{section.title}</h3>
          <p>{section.content}</p>
          {section.buttonText && (
            <button onClick={() => window.location.href = section.buttonLink} aria-label={section.title}>
              {section.buttonText}
            </button>
          )}
        </div>
      </div>
    </animated.div>
  );
};

export default Services;
