import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditableSection from '../components/EditableSection';
import './AdminPage.css';

const AdminPage = () => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    axios.get('/sections.json')
      .then(response => setSections(response.data))
      .catch(error => console.error('Error fetching sections:', error));
  }, []);

  const handleSave = (updatedSection) => {
    axios.put(`/api/sections/${updatedSection.id}`, updatedSection)
      .then(() => {
        setSections(sections.map(section => 
          section.id === updatedSection.id ? updatedSection : section
        ));
      })
      .catch(error => console.error('Error updating section:', error));
  };

  return (
    <div className="admin-page">
      {sections.map((section, index) => (
        <EditableSection key={index} section={section} onSave={handleSave} />
      ))}
    </div>
  );
};

export default AdminPage;
